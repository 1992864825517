import { apiCall } from 'utils/api';
import { NextPageContext } from 'next';

const HOST = `${process.env.NEXT_PUBLIC_API_HOST}/externalUser`;

export const getStoreTheme = async (
  ctx: NextPageContext,
  storeHandle: string
) => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/stores/${storeHandle}/themeId?cachekey=${storeHandle}`,
    ctx,
  });
  return res.data;
};

export const getCartCount = async () => {
  const res = await apiCall({
    method: 'get',
    url: `${HOST}/carts/cartCount`,
  });
  return { cartDetails: res.data };
};

export const getShop101UserMappingInfo = async (
  ctx: NextPageContext,
  jwt: string
) => {
  let headers = {
    'x-external-token': jwt,
  };
  const res = await apiCall({
    method: 'post',
    url: `${HOST}/shop101UserMappingInfo`,
    data: {},
    headers,
    ctx,
  });
  return { externalUserData: res.data };
};
