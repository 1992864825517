export const SHOP_CARD_TYPE = {
  TRENDS_CARD: 'trendsCard',
  PRODUCT_DROPS: 'productDrops',
  VIDEO_CARD: 'videoCard',
  CARD_CFT: 'cardCFT',
  PRODUCT_CARD: 'productCard',
};
export const productDropShape = {
  CIRCLE: 'circle',
  ROUNDED_CORNER: 'rounded_corner',
  DOME: 'dome',
};
export const ribbonPosition = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};

export const adConfig = {
  Shoptab_fixed_banner: {
    containerID: 'Shoptab_fixed_banner',
    placementName: 'StickySmall',
    pageName: 'others',
    adUnitName: 'Shopping_New_HP_Sticky',
    height: 50,
    width: 320,
    adName: 'small_fixed_banner',
    categoryName: null,
  },
  Video_infeed_mrec: {
    containerID: 'Video_infeed_mrec',
    placementName: 'InArticleMedium',
    pageName: 'others',
    adUnitName: 'Shopping_Infeed',
    height: 480,
    width: 320,
    adName: 'video_infeed_mrec',
    categoryName: null,
  },
};

export enum OEM {
  XIAOMI = 'xiaomi',
  REALME = 'realme',
  SAMSUNG = 'samsungdlss',
}

export const genderConst = {
  HIM: 'him',
  HER: 'her',
};

export const genderMappingConst: { [key: string]: string } = {
  him: 'men',
  her: 'women',
  men: 'men',
  women: 'women',
};

export const shoptabRoutes = {
  mainFeed: '/feed',
  collection: '/feed/collection',
  playAndWin: '/feed/play-to-win',
  videoFeed: '/feed/video-feed',
  onboarding: '/feed/onboarding',
};

export const notShoptabRoutes = {
  returnsLogin: '/returns/login',
  returnsOrders: '/returns/orders',
};

export const adEvents = {
  onAdLoadSucceed: 'onAdLoadSucceed',
  onAdLoadFailed: 'onAdLoadFailed',
  onAdImpression: 'onAdImpression',
  onAdClicked: 'onAdClicked',
};

export const OEM_PACKAGENAME_MAP = {
  xiaomi: [
    'com.miui.android.fashiongallery',
    'glance.sample.standalone.testbed.xiaomi',
    'glance.sample.standalone.xiaomi',
  ],
  realme: [
    'com.glance.internet',
    'com.glance.lockscreenRealmeLite',
    'com.glance.lockscreenRealme',
  ],
  samsung: [
    'com.samsung.android.dynamiclock',
    'com.samsung.android.keyguardmgsupdator',
  ],
  vivo: ['com.vivo.magazine'],
  oppo: ['com.heytap.pictorial', 'com.coloros.pictorial'],
  moto: ['com.glance.lockscreenMR'],
  motoaosp: ['com.glance.lockscreenM'],
  motogo: ['com.glance.lockscreenMLite'],
  jio: ['com.glance.lockscreenJioNext'],
};
