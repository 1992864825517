/* eslint-disable @next/next/no-page-custom-font */
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
// import Script from "next/script";
import { v4 } from 'uuid';
import Head from 'next/head';
import NProgress from 'nprogress';
import React, { createContext, useEffect, useRef, useState } from 'react';
import '/styles/globals.css';
import '/styles/nprogress.css';
import 'simple-keyboard/build/css/index.css';
import '/styles/virtualkeyboard.css';
import { COOKIES, getCookie, getCookieDomain, setCookie } from 'utils/cookies';
import {
  getStoreTheme,
  // getShop101UserMappingInfo,
} from 'data/externalUser';
// import {resolveMergeConflict} from "data/shopping";
import {
  // getGlancePreference,
  isGlance,
  isRoposo,
  setGlancePreference,
  // isGlance,
  setJwtInPreferenceStore,
} from 'utils/app-native';
// import throttle from 'lodash.throttle';
import dynamic from 'next/dynamic';
// import { initialiseAd, renderAd } from "utils/bannerAds";
import { AdContainer } from 'components/Ads';
import { notShoptabRoutes, shoptabRoutes } from 'constants/constants'; // adConfig, adEvents,
import { NavMenuI, WindowInterface } from 'types';
import {
  getCurrentPageName,
  getGuestUserId,
  handleCreateSessionId,
} from 'utils/misc';
import { fetchConfig, setGuestUserId } from 'data/shoptab';
import {
  shoptabContext,
  triggerPageSessionStartEvent,
  triggerClickSessionStartEvent,
  triggerPageSessionEndEvent,
  triggerClickSessionEndEvent,
} from 'utils/analytics';
import logger from 'utils/logger';
// import {isUserLoggedIn} from 'utils/auth';
// import {redirectServerSide} from 'utils/misc';
export const myWindow: WindowInterface | null =
  typeof window !== 'undefined' ? window : null;
export const GlobalContext = createContext<any>({});
const RoposoSplashScreen = dynamic(
  () => import('components/RoposoSplashScreen/RoposoSplashScreen'),
  {
    loading: () => (
      <div className={'absolute top-0 z-[100] h-full w-full bg-white'} />
    ),
  }
);

type O1AppProps = AppProps & {
  themeId: number;
};

function MyApp({ Component, pageProps, themeId, router }: O1AppProps) {
  const [hideRoposoLoader, setHideRoposoLoader] = useState(true);
  const [isAdInitialised] = useState(false); // updateAdInitState
  const [isShoptabActive, setShoptabActiveState] = useState(false);
  const [isFixedBannerLoaded, setBannerLoadedState] = useState(false);
  // const [fixedBannerAdInstance, setFixedBannerAdInstance] = useState<any>(null);
  const [shoptabConfig, setShoptabConfig] = useState<any>(null);
  const [guestUserId, updateGuestUserId] = useState(getGuestUserId());
  const isExternalAppNameGlance = router.query.externalappname === 'glance';
  const isFocusEventRegistered = useRef(false);
  const [mainFeed, updateMainFeed] = useState<any>([]);
  const [mainFeedPaginationKey, updateMainFeedPaginationKey] = useState(1);
  const menuObj: any = {};
  const [navigationMenu, updateNavigationMenu] = useState<NavMenuI>(menuObj);
  const [cartCount, updateCartCount] = useState<number>(-1);
  // const scrollHandler = throttle((_e: null, override = false) => {
  //   if (override || window.scrollY < 50) {
  //     window.scrollTo(0, 50);
  //   }
  // }, 300);

  if (myWindow && !isFocusEventRegistered.current) {
    console.log('onfocus registred', new Date().getTime());
    isFocusEventRegistered.current = true;
    myWindow.onFocus = () => {
      console.log('onfocus called', new Date().getTime());
      setShoptabActiveState(true);
    };
    myWindow.outOfFocus = () => {
      // fixedBannerAdInstance?.destroyAd();
      setBannerLoadedState(false);
      setShoptabActiveState(false);
    };
  }

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' &&
      isShoptabActive
    ) {
      import('react-facebook-pixel')
        .then((lib) => lib?.default)
        .then((FBPixel) => {
          // @ts-ignore
          FBPixel?.init?.(process.env.NEXT_PUBLIC_FB_PIXEL_ID);
          FBPixel?.pageView?.();

          router.events.on('routeChangeComplete', () => {
            FBPixel?.pageView();
          });
        });
    }
  }, [router.events, isShoptabActive]);

  useEffect(() => {
    handleCreateSessionId();
    updateGuestUserId(getGuestUserId());
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'auto';
    }
  }, []);

  useEffect(() => {
    const callback = () => {
      setShoptabActiveState(true);
      myWindow?.removeEventListener('touchstart', callback);
      myWindow?.removeEventListener('scroll', callback);
    };
    //Preloading only happen in Xiaomi glance and Roposo APP
    if (isGlance() || isRoposo()) {
      myWindow?.addEventListener('touchstart', callback);
      myWindow?.addEventListener('scroll', callback);
    } else {
      // window.onblur = () => {
      //   setShoptabActiveState(false);
      // };
      // window.onfocus = () => {
      //   setShoptabActiveState(true);
      // };
      setShoptabActiveState(true);
    }

    return () => {
      myWindow?.removeEventListener('touchstart', callback);
      myWindow?.removeEventListener('scroll', callback);
    };
  }, []);

  // useEffect(() => {
  //   let adInstance: any = null;
  //   if (isAdInitialised && isShoptabActive && !isFixedBannerLoaded && !router?.pathname?.includes("/video-feed") && (router.pathname !== "/feed")) {
  //     const onSuccess = () => {
  //       setBannerLoadedState(true);
  //     };
  //     const onFail = () => {
  //       setBannerLoadedState(false);
  //     };
  //     /*if (router?.pathname?.includes("video-feed")) {
  //       adConfig.Shoptab_fixed_banner.adUnitName = 'Shopping_Video_Sticky';
  //     }*/
  //     adInstance = renderAd(
  //       router,
  //       { ...adConfig.Shoptab_fixed_banner },
  //       {
  //         [adEvents.onAdLoadSucceed]: onSuccess,
  //         [adEvents.onAdLoadFailed]: onFail,
  //       }
  //     );
  //     setFixedBannerAdInstance(adInstance);
  //     return () => {
  //       adInstance?.destroyAd?.();
  //     };
  //   } else {
  //     adInstance?.destroyAd?.();
  //   }
  // }, [isAdInitialised, isShoptabActive, router.pathname]);

  useEffect(() => {
    // onboard user if not onboarded and not on  '/onboarding' page
    // call config API after onboarding user if not onboarded, if onboarded then call whenever
    if (
      isShoptabActive &&
      ![notShoptabRoutes.returnsLogin, notShoptabRoutes.returnsOrders].includes(
        router.asPath
      )
    ) {
      const isUserOnboarded = true; // getGlancePreference("isUserOnboarded")
      const currentPage = getCurrentPageName(router);
      if (isUserOnboarded && currentPage === shoptabContext.ONBOARDING) {
        setGlancePreference('isUserOnboarded', `${isUserOnboarded}`);
        router.push(shoptabRoutes.mainFeed);
      }

      const handleConfig = async () => {
        try {
          const res = await fetchConfig(guestUserId);
          if (res) {
            const isUserOnboarded = !res.showOnboarding;
            setShoptabConfig(res);
            setGlancePreference('isUserOnboarded', `${isUserOnboarded}`);
          }
        } catch (err) {
          console.log(err);
        }
      };

      if (isUserOnboarded && currentPage !== shoptabContext.ONBOARDING) {
        const createUser = async () => {
          try {
            const res = await setGuestUserId({
              guestId: guestUserId,
              platform: 'web',
            });

            if (res?.status === 'success') {
              setGlancePreference('isUserOnboarded', 'true');
            }
          } catch (err) {
            console.log(err);
          }

          handleConfig();
        };
        createUser();
      } else if (!shoptabConfig) {
        handleConfig();
      }
    }
  }, [isShoptabActive]);

  useEffect(() => {
    // to handle & trigger Click-&-Page-Session events
    const triggerSessionEvent = (startSession: boolean) => {
      if (startSession) {
        triggerClickSessionStartEvent(router);
        triggerPageSessionStartEvent(router);
      } else {
        triggerClickSessionEndEvent(router);
        triggerPageSessionEndEvent(router);
      }
    };

    if (isShoptabActive) {
      document.addEventListener('visibilitychange', () => {
        triggerSessionEvent(document.visibilityState === 'visible');
      });
      triggerSessionEvent(true);
    }

    return () => {
      if (isShoptabActive) {
        document.removeEventListener('visibilitychange', () => {
          triggerSessionEvent(document.visibilityState === 'visible');
        });
      }
    };
  }, [isShoptabActive]);

  // to handle & trigger page session end and start on route change
  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (isShoptabActive) {
        triggerPageSessionEndEvent(router);
      }
    });
    router.events.on('routeChangeComplete', () => {
      if (isShoptabActive) {
        triggerPageSessionStartEvent(router);
      }
    });
  }, [router, isShoptabActive]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      if (isShoptabActive) {
        triggerPageSessionEndEvent(router);
      }
      NProgress.configure({ showSpinner: false }).start();
    });
    router.events.on('routeChangeComplete', () => {
      NProgress.done();
      if (isShoptabActive) {
        triggerPageSessionStartEvent(router);
      }
    });
    router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }, [router]);

  useEffect(() => {
    // hacky solution to fix vertical scroll issue
    // if (isGlance()) {
    //   document.getElementsByTagName('body')[0].style.paddingTop = '50px';
    //   setTimeout(() => {
    //     scrollHandler(null, true);
    //   }, 100);
    //   window.addEventListener('load', () => {
    //     setTimeout(() => {
    //       scrollHandler(null, true);
    //     }, 100);
    //   });
    //   window.addEventListener('scroll', () => {
    //     scrollHandler(null, false)
    //   });
    // }
    if (isExternalAppNameGlance) {
      setHideRoposoLoader(Boolean(getCookie(null, COOKIES.hideRoposoLoader)));
    }
    setJwtInPreferenceStore();
  }, []);
  /* const onLoadMlib = () => {
    if (![notShoptabRoutes.returnsLogin, notShoptabRoutes.returnsOrders].includes(router.asPath)) {
      initialiseAd(
        router,
        () => {
          sessionStorage.setItem("isAdInitialised", `${true}`);
          updateAdInitState(true);
        },
        (err: any) => {
          console.error(err);
        }
      );
    }
  }; */
  const updateMainFeedData = (feed: any, paginationKey: number) => {
    updateMainFeed(feed);
    updateMainFeedPaginationKey(paginationKey);
  };

  const setNavigationMenuData = (data: NavMenuI) => {
    updateNavigationMenu(data);
  };

  const setCartCount = (count: number) => {
    updateCartCount(count);
  };
  return (
    <div>
      {/* <Script
        async
        src={"https://nebula.glance-cdn.com/public/content/assets/other/awc.js"}
        onLoad={onLoadMlib}
      ></Script> */}
      <Head>
        <title>Roposo</title>
        {themeId === 11 && (
          <link
            href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap'
            rel='preload'
            as='style'
          />
        )}
        {themeId === 12 && (
          <link
            href='https://fonts.googleapis.com/css2?family=Urbanist:wght@400;600;700;800;900&display=swap'
            rel='preload'
            as='style'
          />
        )}
        {themeId === 13 && (
          <>
            <link
              href='https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600;700&display=swap'
              rel='preload'
              as='style'
            />
            <link
              href='https://fonts.googleapis.com/css2?family=Sora:wght@300;400;600;700&display=swap'
              rel='stylesheet'
              as='style'
            />
          </>
        )}
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Head>
      <GlobalContext.Provider
        value={{
          isAdInitialised,
          isShoptabActive,
          isFixedBannerLoaded,
          navigationMenu,
          cartCount,
          setNavigationMenuData,
          setCartCount,
        }}
      >
        <Component
          {...pageProps}
          themeId={themeId}
          isShoptabActive={isShoptabActive}
          isAdInitialised={isAdInitialised}
          mainFeed={mainFeed}
          mainFeedPaginationKey={mainFeedPaginationKey}
          updateMainFeedData={updateMainFeedData}
        />
      </GlobalContext.Provider>

      {isExternalAppNameGlance && !hideRoposoLoader && <RoposoSplashScreen />}
      <AdContainer
        styleClass={'fixed bottom-0 z-10'}
        containerId={'Shoptab_fixed_banner'}
      />
    </div>
  );
}

MyApp.getInitialProps = async (appCtx: AppContext) => {
  if (typeof window === 'undefined') {
    logger.info(
      `[Page][Request] GET ${appCtx.ctx.req?.url} ${JSON.stringify(appCtx.ctx.req?.headers)}`
    );
  }
  const appProps = await App.getInitialProps(appCtx);

  // let authToken = '';
  const domain = getCookieDomain(appCtx.ctx.req?.headers?.host || '');
  let themeId = getCookie(appCtx.ctx, COOKIES.themeId);
  const lastVisitedStoreHandle =
    getCookie(appCtx.ctx, COOKIES.lastVisitedStoreHandle) || '';
  let deviceId = getCookie(appCtx.ctx, COOKIES.deviceId);
  if (!deviceId || appCtx.router.query?.userid) {
    deviceId = v4();
    setCookie(
      appCtx.ctx,
      COOKIES.deviceId,
      appCtx.router.query.userid?.toString() || deviceId?.toString()
    );
    setCookie(
      appCtx.ctx,
      COOKIES.roposoDeviceId,
      appCtx.router.query.userid?.toString() || deviceId?.toString(),
      domain
    );
  }
  if (
    appCtx.router.query?.storeHandle ||
    appCtx.router.query?.storehandle ||
    lastVisitedStoreHandle
  ) {
    const storeHandle =
      appCtx.router.query.storeHandle?.toString() ||
      appCtx.router.query.storehandle?.toString() ||
      lastVisitedStoreHandle;
    const res = await getStoreTheme(appCtx.ctx, storeHandle);
    themeId = res.themeId;
    setCookie(appCtx.ctx, COOKIES.themeId, themeId?.toString());
    setCookie(appCtx.ctx, COOKIES.lastVisitedStoreId, res.storeId);
    setCookie(appCtx.ctx, COOKIES.lastVisitedStoreHandle, storeHandle);
  }

  // const jwtCookie = getCookie(appCtx.ctx, COOKIES.roposoLoginJwt, domain)

  // if ((appCtx.router.query.externalappjwt || jwtCookie) && !isUserLoggedIn(appCtx.ctx)) {
  //   const jwt = appCtx.router.query.externalappjwt?.toString() || jwtCookie;
  //   setCookie(appCtx.ctx, COOKIES.externalAppJwt, jwt);
  //   const resShop101Mapping = await getShop101UserMappingInfo(appCtx.ctx, jwt);
  //   authToken = resShop101Mapping?.externalUserData?.authToken;
  //   const redirectRoposoLogin = resShop101Mapping?.externalUserData?.redirectRoposoLogin;
  //   if (redirectRoposoLogin) {
  //     setCookie(appCtx.ctx, COOKIES.redirectRoposoLogin, redirectRoposoLogin);
  //   }
  //   if (authToken) {
  //     setCookie(appCtx.ctx, COOKIES.shop101Session, JSON.stringify(resShop101Mapping.externalUserData), domain);
  //   }
  //   if (resShop101Mapping?.externalUserData?.authToken && appCtx.router.query.redirectFromLogin) {
  //     await resolveMergeConflict(appCtx.ctx,
  //       jwt);
  //     // deleteCookie(appCtx.ctx, COOKIES.roposoLoginJwt, domain);
  //   }
  // }
  // if (appCtx.router.pathname === '/payment-review') {
  //   if (!(isUserLoggedIn(appCtx.ctx) || authToken)) {
  //     const storeHandle = appCtx.router.query.storeHandle?.toString() || appCtx.router.query.storehandle?.toString() || getCookie(appCtx.ctx, COOKIES.lastVisitedStoreHandle) || '';
  //     const userId = appCtx.router.query.userid?.toString() || deviceId.toString() || getCookie(appCtx.ctx, COOKIES.deviceId);
  //     const externalAppName = appCtx.router.query.externalappname?.toString() || getCookie(appCtx.ctx, COOKIES.externalAppName, domain);
  //     const roposoStoreId = appCtx.router.query.roposoStoreId?.toString() || getCookie(appCtx.ctx, COOKIES.roposoStoreId);
  //     return redirectServerSide(appCtx.ctx, `${process.env.NEXT_PUBLIC_ROPOSO_LOGIN_URL}/login?redirect_uri=${encodeURIComponent(`${appCtx.ctx.req?.headers?.host}/payment-review?userid=${userId}&storehandle=${storeHandle}&externalappname=${externalAppName}&roposostoreid=${roposoStoreId}&redirectFromLogin=true`)}`);
  //   }
  // }
  if (appCtx.router.query?.externalappname) {
    setCookie(
      appCtx.ctx,
      COOKIES.externalAppName,
      appCtx.router.query.externalappname?.toString()
    );
    setCookie(
      appCtx.ctx,
      COOKIES.roposoExternalAppName,
      appCtx.router.query.externalappname?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.gpid) {
    setCookie(
      appCtx.ctx,
      COOKIES.gpid,
      appCtx.router.query.gpid?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.src) {
    setCookie(
      appCtx.ctx,
      COOKIES.src,
      appCtx.router.query.src?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.sessionid) {
    setCookie(
      appCtx.ctx,
      COOKIES.sessionId,
      appCtx.router.query.sessionid?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.streamid) {
    setCookie(
      appCtx.ctx,
      COOKIES.streamId,
      appCtx.router.query.streamid?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.storyid || appCtx.router.query?.storyId) {
    setCookie(
      appCtx.ctx,
      COOKIES.storyId,
      (
        appCtx.router.query.storyid ||
        appCtx.router.query.storyId ||
        ''
      ).toString(),
      domain
    );
  }
  if (appCtx.router.query?.creatorid) {
    setCookie(
      appCtx.ctx,
      COOKIES.externalCreatorId,
      appCtx.router.query.creatorid?.toString(),
      domain
    );
  }
  if (
    appCtx.router.query?.isBuyNow &&
    ['true', 'false'].includes(appCtx.router.query?.isBuyNow?.toString())
  ) {
    setCookie(
      appCtx.ctx,
      COOKIES.isBuyNow,
      appCtx.router.query.isBuyNow.toString(),
      domain
    );
  }
  if (appCtx.router.query?.buyNowVariantId) {
    setCookie(
      appCtx.ctx,
      COOKIES.buyNowVariantId,
      appCtx.router.query.buyNowVariantId?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.buyNowVariantQuantity) {
    setCookie(
      appCtx.ctx,
      COOKIES.buyNowVariantQuantity,
      appCtx.router.query.buyNowVariantQuantity?.toString(),
      domain
    );
  }
  if (appCtx.router.query?.roposoStoreId) {
    setCookie(
      appCtx.ctx,
      COOKIES.roposoStoreId,
      appCtx.router.query.roposoStoreId?.toString()
    );
  }

  // Additional cookie handling for userLocationId and userPincode and cartHash
  if (appCtx.router.query?.userLocationId) {
    setCookie(
      appCtx.ctx,
      COOKIES.shop101SelectedAddressId,
      appCtx.router.query.userLocationId?.toString()
    );
  }
  if (appCtx.router.query?.userPincode) {
    setCookie(
      appCtx.ctx,
      COOKIES.shop101DeliveryPincode,
      appCtx.router.query.userPincode?.toString()
    );
  }
  if (appCtx.router.query?.cartHash) {
    setCookie(
      appCtx.ctx,
      COOKIES.shop101CartHash,
      appCtx.router.query.cartHash?.toString()
    );
  }

  return { ...appProps, themeId: 13 };
};

export default MyApp;
