type AdProps = {
  styleClass?: string;
  containerId: string;
};
export const AdContainer = ({ styleClass, containerId }: AdProps) => {
  return (
    <div
      className={`flex w-full items-center justify-center bg-theme_12_white ${styleClass}`}
    >
      <div id={containerId}></div>
    </div>
  );
};
