import { WindowInterface } from 'types';
import { NextRouter } from 'next/router';
import { redirectRoposoLogin } from './auth';
import { redirect } from './misc';
import { COOKIES, getCookie, getCookieDomain } from './cookies';

export const myWindow: WindowInterface | null =
  typeof window !== 'undefined' ? window : null;

export const isGlanceLocked = () => {
  return (
    myWindow?.GlanceAndroidInterface &&
    myWindow?.AndroidUtils?.isKeyguardLocked()
  );
};

export const isGlance = () => {
  return !!myWindow?.GlanceAndroidInterface;
};

export const isRoposoAndroidEnvironment = () => {
  if (myWindow?.appEventsBridge) {
    return true;
  }
  return false;
};

export const isRoposoIOSEnviroment = () => {
  if (
    typeof myWindow?.webkit?.messageHandlers?.liveBridge?.postMessage ===
    'function'
  ) {
    return true;
  }
  return false;
};

export const isRoposo = () => {
  if (isRoposoAndroidEnvironment() || isRoposoIOSEnviroment()) {
    return true;
  }
  return false;
};

export const openUrlInBrowserInGlance = (url: string) => {
  if (myWindow?.GlanceAndroidInterface) {
    return myWindow?.GlanceAndroidInterface.launchIntentAfterUnlock(
      'android.intent.action.VIEW',
      url
    );
  }
  return myWindow?.open(url);
};

export const unlockGlance = () => {
  return myWindow?.GlanceAndroidInterface?.launchIntentAfterUnlock(null, '');
};

export const goBackGlance = () => {
  return myWindow?.GlanceAndroidInterface?.closeBottomSheet();
};

export const goBack = (router: NextRouter) => {
  if (isGlance()) {
    goBackGlance();
  } else if (myWindow?.navBridge && myWindow?.navBridge.goBack) {
    myWindow?.navBridge.goBack();
  } else if (
    myWindow?.webkit &&
    myWindow?.webkit.messageHandlers &&
    myWindow?.webkit.messageHandlers.liveBridge &&
    myWindow?.webkit.messageHandlers.liveBridge.postMessage
  ) {
    const data = JSON.stringify({
      event: 'WEB_VIEW_BACK_CLICKED',
      data: {},
    });
    myWindow?.webkit.messageHandlers.liveBridge.postMessage(data);
  } else {
    router.back();
  }
};

export const performActionAfterUnlock = (postUnlockAction: Function) => {
  let pollingActionIntervalId: any, pollingActionTimeoutId: any;
  pollingActionIntervalId = sessionStorage.getItem('pollingActionIntervalId');
  pollingActionTimeoutId = sessionStorage.getItem('pollingActionTimeoutId');

  if (pollingActionIntervalId) {
    clearInterval(pollingActionIntervalId);
    clearTimeout(pollingActionTimeoutId);
  }

  pollingActionIntervalId = setInterval(() => {
    if (!isGlanceLocked()) {
      postUnlockAction();
      clearInterval(pollingActionIntervalId);
      pollingActionIntervalId = 0;
      sessionStorage.removeItem('pollingActionIntervalId');
    }
  }, 100);
  sessionStorage.setItem('pollingActionIntervalId', pollingActionIntervalId);

  pollingActionTimeoutId = setTimeout(() => {
    if (pollingActionIntervalId) {
      clearInterval(pollingActionIntervalId);
      pollingActionIntervalId = 0;
      sessionStorage.removeItem('pollingActionIntervalId');
    }
  }, 15000);
  sessionStorage.setItem('pollingActionTimeoutId', pollingActionTimeoutId);
};

export const roposoNativeLogin = (pathname: string) => {
  const domain = getCookieDomain(myWindow?.location?.host || '');

  const storeHandle = getCookie(null, COOKIES.lastVisitedStoreHandle) || '';
  const userId = getCookie(null, COOKIES.deviceId);
  const externalAppName = getCookie(null, COOKIES.externalAppName, domain);
  const roposoStoreId = getCookie(null, COOKIES.roposoStoreId);
  if (
    redirectRoposoLogin() &&
    myWindow?.authenticationBridge?.loginAndRedirect
  ) {
    return myWindow?.authenticationBridge?.loginAndRedirect(
      `${myWindow?.location?.origin}${pathname}?userid=${userId}&storehandle=${storeHandle}&externalappname=${externalAppName}&roposostoreid=${roposoStoreId}&redirectFromLogin=true`
    );
  } else {
    redirect({
      pathname: `${process.env.NEXT_PUBLIC_ROPOSO_LOGIN_URL}/login?redirect_uri=${encodeURIComponent(`${window.location.origin}${pathname}?userid=${userId}&storehandle=${storeHandle}&externalappname=${externalAppName}&submitCart=1&roposostoreid=${roposoStoreId}&redirectFromLogin=true`)}`,
    });
  }
};

export function putPreferenceString(key: string, value: string) {
  try {
    myWindow?.PreferencesStore?.putString(key, value);
  } catch (err) {
    console.log(err);
  }
}

export function getPreferenceString(key: string, defaultValue: string) {
  try {
    return myWindow?.PreferencesStore?.getString(key, defaultValue);
  } catch (err) {
    console.log(err);
    return defaultValue;
  }
}

export const setJwtInPreferenceStore = () => {
  const domain = getCookieDomain(myWindow?.location?.host || '');
  //updating glance app when logging in from web interface.
  if (
    getCookie(null, COOKIES.roposoLoginJwt, domain) &&
    myWindow?.PreferencesStore &&
    getPreferenceString('glance_roposo_jwe_token', '') !==
      getCookie(null, COOKIES.roposoLoginJwt, domain)
  ) {
    putPreferenceString(
      'glance_roposo_jwe_token',
      getCookie(null, COOKIES.roposoLoginJwt, domain)
    );
    putPreferenceString(
      'glance_roposo_spice',
      getCookie(null, COOKIES.spice, domain)
    );
    putPreferenceString(
      'glance_roposo_sugar',
      getCookie(null, COOKIES.sugar, domain)
    );
    putPreferenceString(
      'glance_roposo_user_id',
      getCookie(null, COOKIES.userIdUsr, domain)
    );
  }
};

export const toggleNumericKeyboardGlance = (flag: boolean) => {
  return myWindow?.GlanceAndroidInterface?.enableNumericKeyboard(flag);
};

export const getGlanceAppVersionCode = () => {
  return myWindow?.AndroidUtils?.getBuildVersionCode();
};

export const openNativeKeyboard = () => {
  return myWindow?.GlanceAndroidInterface?.openNativeKeyboard();
};

export const getGlanceSdkVersion = () => {
  return (
    myWindow?.GlanceAndroidInterface &&
    myWindow.GlanceAndroidInterface.getGlanceSdkVersion()
  );
};

export function setGlancePreference(key: string, value: string) {
  try {
    if (typeof myWindow?.PreferencesStore?.putString === 'function') {
      myWindow.PreferencesStore.putString(key, value);
    } else {
      myWindow?.localStorage.setItem(key, value);
    }
  } catch (err) {
    console.error(err);
  }
}

export function getGlancePreference(key: string, defaultValue: string = '') {
  try {
    if (typeof myWindow?.PreferencesStore?.getString === 'function') {
      return myWindow.PreferencesStore.getString(key, defaultValue);
    } else {
      return myWindow?.localStorage.getItem(key) || defaultValue;
    }
  } catch (err) {
    console.error(err);
  }
}

export function removeGlancePreference(key: string) {
  try {
    if (typeof myWindow?.PreferencesStore?.remove === 'function') {
      return myWindow.PreferencesStore.remove(key);
    } else {
      return myWindow?.localStorage?.removeItem(key);
    }
  } catch (err) {
    console.error(err);
  }
}

export function getGlanceUserData() {
  return (
    myWindow?.ShopTabInterface && myWindow?.ShopTabInterface?.getUserDataModel()
  );
}

export function getAdProvider(config: any) {
  return myWindow?.GlanceAdClientInterface?.getProvider(config);
}
export function initAd(config: any) {
  return myWindow?.GlanceAdClientInterface?.init(config);
}

export function getRegion() {
  try {
    if (typeof myWindow?.GlanceAndroidInterface?.getRegion === 'function') {
      return myWindow?.GlanceAndroidInterface?.getRegion() || 'IN';
    }
  } catch (err) {
    console.error(err);
  }
  return 'IN';
}

export function openCtaUrl(url: string, loadAndroidJS: boolean) {
  if (typeof myWindow?.GlanceAndroidInterface?.openCtaUrl === 'function') {
    myWindow.GlanceAndroidInterface.openCtaUrl(url, loadAndroidJS);
  } else if (myWindow) {
    myWindow.location.href = url;
  }
}

export const isAppInstalled = (packageName: string): boolean => {
  if (typeof myWindow?.GlanceAndroidInterface !== 'undefined') {
    return myWindow?.GlanceAndroidInterface.isAppInstalled(packageName);
  }
  return false;
};
