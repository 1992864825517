import Swal from 'sweetalert2';

export const showError = (text: string) => {
  return Swal.fire({
    title: 'Error',
    text,
    icon: 'error',
    confirmButtonColor: '#fd3560',
  });
};

export const showGenericError = () => {
  return Swal.fire({
    title: 'Error',
    text: 'Something went wrong',
    icon: 'error',
    confirmButtonColor: '#fd3560',
  });
};
